<template>
	<v-app>
		<ti-toast></ti-toast>
		<minimal-view/>
	</v-app>
</template>

<script>
export default {
	name: 'DefaultLayout',

	components: {
		MinimalView: () => import(/* webpackChunkName: "default-view" */'./View'),
	},
}
</script>
